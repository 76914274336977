
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button, Icon } from 'ant-design-vue';
import { downloadLeadPDF } from '@/helpers/NewProject/LeadHelper';
import Project from '@/models/Project';

@Component({
    name: 'DownloadLeadPDF',
    components: {
        Icon,
        Button,
    },
})
export default class DownloadLeadPDF extends Vue {
    @Prop({ required: true }) private projectId!: string;
    @Prop({ required: true }) private isEditMode!: boolean;
    @Prop({ required: true }) private onSave!: () => Promise<void>;
    @Prop({ required: true }) private project!: Project;

    private async leadPDFPrint() {
        if (this.isEditMode) {
            await this.onSave();
        }
        await downloadLeadPDF(this.project);
    }
}
