
import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import { Button, Checkbox, Divider, Form, Input, InputNumber, Select } from 'ant-design-vue';
import { fetchLeadDropdownOptions, getDecoratorRules, validateAndSubmitLead } from '@/helpers/NewProject/LeadHelper';
import { LeadFormFields } from '@/enums/components/ProjectNew/LeadFormFields';
import LeadOriginRepository from '@/repositories/LeadOriginRepository';
import { IformCreateOption } from 'ant-design-vue/types/form/form';
import TwoRowWithTag from '@/components/global/TwoRowWithTag.vue';
import LeadTypeRepository from '@/repositories/LeadTypeRepository';
import CampaignRepository from '@/repositories/CampaignReporistory';
import MultipleSelect from '@/components/global/MultipleSelect.vue';
import LeadProductRepository from '@/repositories/LeadProductRepository';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import { AxiosError } from 'axios';

@Component({
    name: 'LeadForm',
    components: {
        Form,
        FormItem: Form.Item,
        Checkbox,
        Divider,
        Input,
        InputNumber,
        Select,
        SelectOption: Select.Option,
        Button,
        TwoRowWithTag,
        Textarea: Input.TextArea,
        MultipleSelect,
    },
})
export default class LeadForm extends Vue {
    @Prop({ default: null }) private projectId!: string;
    @Prop({ required: true }) private project!: Project;
    private leadFormFields = LeadFormFields;

    private get form() {
        return this.$form.createForm(this, {
            name: 'leadForm',
        } as IformCreateOption);
    }

    private get decoratorRules() {
        return getDecoratorRules(this.project.lead);
    }

    private get importText() {
        return this.project.lead.importText || '-';
    }

    private get leadOrigins() {
        return LeadOriginRepository.getAll();
    }

    private get leadTypes() {
        return LeadTypeRepository.getAll();
    }

    private get campaigns() {
        return CampaignRepository.getAll();
    }

    private get products() {
        return LeadProductRepository.getAll();
    }

    public async onSubmit(event?: Event) {
        if (event) {
            event.preventDefault();
        }

        try {
            await validateAndSubmitLead({
                leadId: this.project.lead.leadId,
                form: this.form,
            });
        } catch (e) {
            showErrorNotifications(e as AxiosError<any>);
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    private async mounted() {
        await fetchLeadDropdownOptions();
    }
}
