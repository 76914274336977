
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { UploadFile } from 'ant-design-vue/types/upload';
import Attachment from '@/models/Attachment';
import { convertFileToAntdFile } from '@/helpers/CmsIndexHelper';
import Image from '@/models/Image';
// @ts-ignore
import {
    customHandlerForFileUpload,
    downloadGivenFileOrImage,
    updateProjectAttachments,
} from '@/helpers/attachments/AttachmentsHelper';
import { CustomRequestParameters } from '@/interfaces/general/CustomRequestParameters';
import { Upload, Icon } from 'ant-design-vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';

@Component({
    name: 'ProjectAttachmentsNew',
    components: { UploadDragger: Upload.Dragger, Icon },
})
export default class ProjectAttachmentsNew extends Vue {
    @Prop({ required: true }) private leadId!: string;
    @Prop({ default: [] }) private leadAttachments!: Attachment[];

    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private attachmentsForUpload: UploadFile[] = [];
    private uploadingAttachments: boolean = false;
    private attachmentsUploaded = 0;
    private progressStatus = 'active';
    private totalAttachments = 0;

    private get convertedAttachments() {
        return this.leadAttachments.map((attachment: Attachment) => convertFileToAntdFile(attachment));
    }

    private get progressBarPercentage() {
        return Math.round((this.attachmentsUploaded / this.totalAttachments) * 100);
    }

    private async prepareUploadingAttachments(file: UploadFile, files: UploadFile[]) {
        if (this.areAttachmentsAlreadyAdded(files)) {
            return;
        }

        this.resetUploadingStateWatchers('active', true);
        this.totalAttachments = files.length;
        this.attachmentsForUpload = files;

        this.evaluateAndUploadSelectedAttachments(files);
    }

    private areAttachmentsAlreadyAdded(files: UploadFile[]) {
        return files.every((file: UploadFile) => {
            return this.attachmentsForUpload.some((newFile: UploadFile) => file.uid === newFile.uid);
        });
    }

    private async evaluateAndUploadSelectedAttachments(files: UploadFile[]) {
        const uploadPromises = files.map((file: UploadFile) => {
            return this.customRequest({
                file: file as any,
                onSuccess: (formData: FormData) => {
                    this.attachmentsUploaded++;
                },
                onError: () => {
                    this.resetUploadingStateWatchers('exception');

                    this.$notification.error({
                        message: '',
                        description: this.$t('Error uploading attachments'),
                    });
                    return;
                },
            });
        });

        try {
            await Promise.all(uploadPromises);
        } catch {
            return Promise.reject();
        } finally {
            EventBus.$emit(EventBusEvents.updateProject);
        }

        this.progressStatus = 'success';
    }

    private resetUploadingStateWatchers(progressStatus: string = 'active', isUploadingAttachments: boolean = false) {
        this.uploadingAttachments = isUploadingAttachments;
        this.progressStatus = progressStatus;
        this.attachmentsUploaded = 0;
    }

    private async customRequest(customRequestParameters: CustomRequestParameters) {
        await customHandlerForFileUpload(customRequestParameters, this.leadId);
    }

    private onOpenRemoveModal(file: UploadFile) {
        return new Promise<void>((resolve) => {
            this.$confirm({
                title: this.$t('Jeste li sigurni da želite primjeniti ove promjene?') as string,
                content: '',
                okText: this.$t('Da') as string,
                okType: 'danger',
                cancelText: this.$t('Ne') as string,
                class: 'c-delete-modal',
                onOk: () => {
                    this.onRemove(file);
                    resolve();
                },
                onCancel: () => {
                    resolve();
                },
            });
        });
    }

    private async onRemove(file: UploadFile) {
        this.loadingOverlay.start();
        Attachment.delete(file.uid);
        const filteredAttachments = this.convertedAttachments.filter((attachment: UploadFile) => {
            return attachment.uid !== file.uid;
        });
        await updateProjectAttachments(this.leadId ?? null, filteredAttachments);
        EventBus.$emit(EventBusEvents.updateProject);
    }

    private async onPreview(file: UploadFile & Image) {
        await downloadGivenFileOrImage(file);
    }

    @Watch('convertedAttachments')
    private resetState() {
        this.loadingOverlay.stop();
        this.resetUploadingStateWatchers();
    }
}
