
import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import ProjectShellComponent from '@/components/views/NewProjectView/ProjectShellComponent.vue';
import LeadDetailsAndForm from '@/components/views/NewProjectView/Lead/LeadDetailsAndForm.vue';
import DownloadLeadPDF from '@/components/views/NewProjectView/Lead/DownloadLeadPDF.vue';
import ProjectAttachmentsNew from '@/components/global/ProjectAttachmentsNew.vue';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';

@Component({
    name: 'ProjectTabLead',
    components: {
        ProjectAttachmentsNew,
        LeadDetailsAndForm,
        ProjectShellComponent,
        DownloadLeadPDF,
    },
})
export default class ProjectTabLead extends Vue {
    @Prop({ required: true }) private projectId!: string;
    @Prop({ default: [] }) private loadedKeys!: Set<ProjectTabValues>;
    @Prop({ required: true }) private project!: Project;
    @Prop({ required: true }) private lockEditing!: boolean;

    public get isProjectLocked() {
        if (this.project == null) {
            return false;
        }
        return this.project.state === ProjectStates.ORDER || this.lockEditing;
    }

    private async leadFormSaveAction() {
        const leadDetailsAndForm = this.$refs.leadDetailsAndForm as LeadDetailsAndForm;
        await leadDetailsAndForm.saveForm();
    }

    private async created() {
        if (this.loadedKeys.has(ProjectTabValues.Lead)) {
            return;
        }
        this.$emit('update:isTabLoading', true);
        this.$emit('update:loadedKeys', new Set([...this.loadedKeys, ProjectTabValues.Lead]));
        this.$emit('update:isTabLoading', false);
    }
}
