import { LeadFormFields } from '@/enums/components/ProjectNew/LeadFormFields';
import Lead from '@/models/Lead';
import LeadProduct from '@/models/LeadProduct';
import Campaign from '@/models/Campaign';
import LeadOrigin from '@/models/LeadOrigin';
import LeadType from '@/models/LeadType';
import { LeadDetailsOptions } from '@/interfaces/components/projectNew/LeadDetailsOptions';
import { LeadSubmitOptions } from '@/interfaces/components/projectNew/LeadSubmitOptions';
import i18n from '@/i18n';
// @ts-ignore
import downloadFile from 'downloadjs';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import Project from '@/models/Project';
import { notification } from 'ant-design-vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { AxiosError } from 'axios';

/**
 * Generates the decorator rules for the lead form
 * @param lead - The lead that is being edited
 * @return An object of decorator rules for each field in the form
 */
export function getDecoratorRules(lead: Lead) {
    return {
        [LeadFormFields.DESCRIPTION]: [
            LeadFormFields.DESCRIPTION,
            {
                initialValue: lead.description,
            },
        ],
        [LeadFormFields.LEAD_ORIGIN]: [
            LeadFormFields.LEAD_ORIGIN,
            {
                initialValue: lead.origin ? lead.origin.id : null,
            },
        ],
        [LeadFormFields.LEAD_TYPE]: [
            LeadFormFields.LEAD_TYPE,
            {
                initialValue: lead.leadType ? lead.leadType.id : null,
            },
        ],
        [LeadFormFields.CAMPAIGN]: [
            LeadFormFields.CAMPAIGN,
            {
                initialValue: lead.campaign ? lead.campaign.id : null,
            },
        ],
        [LeadFormFields.PRODUCTS]: [
            LeadFormFields.PRODUCTS,
            {
                initialValue: transformLeadProductsToArrayOfIds(lead.products),
            },
        ],
        [LeadFormFields.NUMBER_OF_PRODUCTS]: [
            LeadFormFields.NUMBER_OF_PRODUCTS,
            {
                initialValue: lead.numberOfProducts,
            },
        ],
        [LeadFormFields.WINDOWS_BUILT_IN]: [
            LeadFormFields.WINDOWS_BUILT_IN,
            {
                initialValue: lead.windowsBuiltIn,
                valuePropName: 'checked',
            },
        ],
    };
}

/**
 * Maps given lead products to their ids
 * @return An array of lead product ids
 */
export function transformLeadProductsToArrayOfIds(leadProducts: LeadProduct[]) {
    return leadProducts.map((leadProduct) => leadProduct.id);
}

/**
 * Fetches all dropdown sources for the lead form
 * @return A promise
 */
export async function fetchLeadDropdownOptions() {
    return await Promise.all([Campaign.getAll(), LeadOrigin.getAll(), LeadType.getAll(), LeadProduct.getAll()]);
}

/**
 * Runs the validation on the given form entity from Antd design
 * Submits an API request if everything is fine
 * @param options - All options necessary to update the lead entity
 * @return A promise
 */
export async function validateAndSubmitLead(options: LeadSubmitOptions) {
    return new Promise<void>((resolve, reject) => {
        options.form.validateFieldsAndScroll(async (err: Error[], values: LeadDetailsOptions) => {
            try {
                validateLeadsForm(err);
                await Lead.updateLeadDetails(values, options.leadId);
                EventBus.$emit(EventBusEvents.fetchProjectFromRepository);
            } catch (e) {
                return reject(e);
            }

            return resolve();
        });
    });
}

/**
 * Throws an error if there are errors in the form
 * @param errors - An array of errors from the form
 * @return A promise
 */
export function validateLeadsForm(errors: Error[]) {
    if (errors) {
        throw new Error(i18n.t('Postoje greške u formi'));
    }
}

export async function downloadLeadPDF(project: Project | null) {
    let pdf;

    if (project == null) {
        return Promise.reject();
    }

    notification.info({
        message: i18n.t('Ova akcija može potrajati neko vrijeme stoga molimo za strpljenje'),
        description: '',
    });

    try {
        pdf = await Lead.getPdf(project.lead.leadId);
    } catch (e) {
        showErrorNotifications(e as AxiosError<any>);
        return Promise.reject();
    }

    downloadFile(pdf.data, `Lead_${project.projectId}.pdf`, 'application/pdf');
}
