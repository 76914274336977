
import { Component, Prop, Vue } from 'vue-property-decorator';
import TwoRowWithTag from '@/components/global/TwoRowWithTag.vue';
import Project from '@/models/Project';
import { Checkbox, Divider } from 'ant-design-vue';

@Component({
    name: 'LeadDetails',
    components: { TwoRowWithTag, Checkbox, Divider },
})
export default class LeadDetails extends Vue {
    @Prop({ default: null }) private projectId!: string;
    @Prop({ required: true }) private project!: Project;

    private get description() {
        return this.project.lead.description || '-';
    }

    private get importText() {
        return this.project.lead.importText || '-';
    }

    private get leadOrigin() {
        return this.project.lead.origin ? this.project.lead.origin.name : '-';
    }

    private get leadType() {
        return this.project.lead.leadType ? this.project.lead.leadType.name : '-';
    }

    private get campaign() {
        return this.project.lead.campaign ? this.project.lead.campaign.name : '-';
    }

    private get products() {
        if (this.project.lead.products.length === 0) {
            return '-';
        }
        return this.project.lead.products.map((product) => product.name).join(', ');
    }

    private get numberOfProducts() {
        return this.project.lead.numberOfProducts;
    }

    private get areWindowsBuiltIn() {
        return this.project.lead.windowsBuiltIn;
    }
}
