
import { Component, Prop, Vue } from 'vue-property-decorator';
import LeadDetails from '@/components/views/NewProjectView/Lead/LeadDetails.vue';
import { Divider } from 'ant-design-vue';
import Project from '@/models/Project';
import { AddressParentRelationshipNames } from '@/enums/components/ProjectNew/AddressParentRelationshipNames';
import LeadForm from '@/components/views/NewProjectView/Lead/LeadForm.vue';
import AdditionalAddress from '@/components/views/NewProjectView/Lead/AdditionalAddress.vue';
import { EventBus } from '@/helpers/EventBusHelper';

@Component({
    name: 'LeadDetailsAndForm',
    components: { LeadForm, AdditionalAddress, LeadDetails, Divider },
})
export default class LeadDetailsAndForm extends Vue {
    @Prop({ default: null }) private projectId!: string;
    @Prop({ default: false }) private isEditMode!: boolean;
    @Prop({ required: true }) private project!: Project;

    private addressParentRelationshipNames = AddressParentRelationshipNames;

    private get parentRelationshipPayload() {
        return {
            data: [
                {
                    type: `leads`,
                    id: this.project.lead.id,
                },
            ],
        };
    }

    private get leadId() {
        return this.project.lead.id;
    }

    private get objectAddressId() {
        return this.project.lead.objectAddress ? this.project.lead.objectAddress.id : null;
    }

    public async saveForm() {
        const leadForm = this.$refs.leadForm as LeadForm;
        await leadForm.onSubmit();
    }

    private mounted() {
        EventBus.$on('save-form', () => {
            this.saveForm();
        });
    }
}
